.spinnerbox {
    width: 100px;
    display: flex;
}

.spinner {
    margin: auto;
}

.blinkingFont {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.linkColor {
    color: rgba(var(--bs-link-color-rgb))
}