.OverlayWithSpinner {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 10000;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.5);
}

.OverlayWithSpinner h3 {
	margin-right: 15px;
	margin-top: 5px;
}