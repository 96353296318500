:root {
	--card-title-height: 40px;
}

.PageCardContainer {
	width: 80%;
	left: 10%;
	top: 5%;
	height: 90%;
	position: fixed;
	z-index: 10001;
}

.PageCard {
	height: 100%;
	overflow: auto;
}

.VerticallyCentered {
	height: 100%;
	display: flex;
	align-items: center;
}

@media (min-width: 995px) {
	.ColumnSelectionForm {
		width: 40%;
		left: 30%;
		top: 20%;
		height: 60%;
		position: fixed;
		z-index: 10003;
		/* To show on top of SelectCustomerInvoices form (for allocation), which has Backdrop with default zIndex of 10000 and whose own zIndex is 10001 */
	}
}

@media (max-width: 994px) {
	.ColumnSelectionForm {
		width: 80%;
		left: 10%;
		top: 20%;
		height: 60%;
		position: fixed;
		z-index: 10003;
		/* To show on top of SelectCustomerInvoices form (for allocation), which has Backdrop with default zIndex of 10000 and whose own zIndex is 10001 */
	}
}

.ColumnSelectionFormCard {
	height: 100%;
	overflow-x: hidden;
	overflow-y: hidden;
}

.ColumnSelectionFormCardBody {
	height: 100%;
	overflow-y: auto;
}

.RequestBankStatementContainer {
	width: 60%;
	left: 20%;
	top: 20%;
	height: 60%;
	position: fixed;
	z-index: 10001;
}

.EditAllocationCriterionContainer {
	width: 60%;
	left: 20%;
	top: 8%;
	height: 85%;
	position: fixed;
	z-index: 10001;
}

.EditAllocationCriterionCardBody {
	max-height: calc(100% - var(--card-title-height));
	overflow-y: auto;
}

.IntermediaryNonScrollableDiv {
	height: 100%;
	overflow: hidden;
}

.EditTaskOuterDiv {
	width: 70%;
	left: 15%;
	top: 4%;
	height: 92%;
	position: fixed;
	z-index: 10001;
}

.EditTaskCard {
	height: "100%";
	overflow: hidden;
}

.EditTaskCardBody {
	height: calc(100% - var(--card-title-height));
	overflow: hidden;
	/*max-height: calc(100% - var(--card-title-height));
	overflow-y: auto;*/
}

.EditTaskScrollableDiv {
	height: 100%;
	padding-left: 1.5rem;
	padding-right: 1rem;
	overflow-x: hidden;
	overflow-y: auto;
}

.EditScheduledTaskContainer {
	width: 60%;
	left: 20%;
	top: 10%;
	height: 80%;
	position: fixed;
	z-index: 10001;
}

.EditScheduledTaskCardBody {
	max-height: calc(100% - var(--card-title-height));
	overflow-y: auto;
}

.CardTitleDiv {
	width: 100%;
	position: relative;
}

.CardTitleSubDiv {
	display: flex;
	align-items: center;
	width: 100%;
	height: var(--card-title-height);
	justify-content: center;
}

.CloseButtonDiv {
	width: 100%;
	top: 0;
	position: absolute;
}

.CloseButtonDivP {
	text-align: right;
	font-size: 32px;
}

.Background {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 10000;
}

.TagSelectionContainer {
	height: 200px;
	position: fixed;
	z-index: 10001;
}

.TagSelectionList {
	margin-top: 1rem;
	height: 80%;
	overflow: auto;
}

.EditSupplierContainer {
	width: 60%;
	left: 20%;
	top: 14%;
	height: 70%;
	position: fixed;
	z-index: 10001;
}

.EditSupplierContainerCardBody {
	max-height: calc(100% - var(--card-title-height));
	overflow-y: auto;
}

