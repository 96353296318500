.LegalContainer {
	left: 0;
	top: 0;
	width: 100%;
	height: 90%;
	display: flex;
	justify-content: center;
	position: fixed;
}

.LegalCard {
	width: 70%;
	z-index: 101;
	position: absolute;
	top: 50px;
	height: 90%;
	overflow-y: scroll;
	z-index: 10003;
}

.LegalContents {
	text-align: left;
}

.Title {
	text-align: center;
	font-size: 2.5em;
	font-weight: bold;
	margin-bottom: 1.0em;
}

.LegalContents h1 {
	margin-top: 1.0em;
	margin-bottom: 1.0em;
	font-size: 1.8em;
}

.LegalContents h2 {
	margin-bottom: 1.0em;
	font-size: 1.2em;
}

.LegalContents p {
	text-align: left;
}

.CloseButtonContainer {
	text-align: center;
}

.CloseButton {
	margin: 1.0em;
}

@media (max-width: 800px) {
	.LegalCard {
		width: 90%;
	}
}