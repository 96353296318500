.Background {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 10000;
}

.UNECERec20CodeSelectionContainer {
	margin-top: -1rem;
	height: 200px;
    width: 40%;
	position: fixed;
	z-index: 10001;
}

.UNECERec20CodeSelectionList {
	height: 100%;
    width: 100%;
	overflow: auto;
}

.UNECERec20CodeSelectionTable {
    width: 100%;
    border-style: none;
    cursor: pointer;
}
