.Background {
    position: fixed;
    width: 100%;
    height: 100%;
	z-index: 10000;
}

.TagSelectionContainer {
    height: 200px;
	position: fixed;
	z-index: 10001;
}

.TagSelectionList {
    margin-top: 1rem;
	height: 80%;
	overflow: auto;
}

