.SupplierInvoiceCandidateExpandedTable th {
	padding-left: 6rem;
	padding-right: 2rem;
}

.SupplierInvoiceCandidateExpandedTable th, td {
	padding-bottom: 0.5rem;
}

.SupplierInvoiceCandidateExpandedBankAccounts th, td {
    padding-bottom: 0.5rem;
}

.SupplierInvoiceCandidateExpandedBankAccounts th, td {
	padding-right: 1rem;
}